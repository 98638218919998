function Teams() {
  return (
    <div>
      <h1>Select Team</h1>
      <p>Place holder for eventual Map element</p>
    </div>
  );
}

export default Teams;
