function NotFound(){
    return(
        <div id="Wrapper404" >
            <h1 id="Header404"> 404 NOT FOUND</h1>
            <h3 id="SubHeader404">Sometimes you make an error</h3>
            <img id="Gif404" src="/Images/404.gif"></img>
        </div>
    )
}

export default NotFound